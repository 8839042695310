/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    code: "code",
    a: "a",
    pre: "pre",
    h3: "h3",
    h4: "h4",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Using ", React.createElement(_components.code, null, "printf")), "\n", React.createElement(_components.h2, null, React.createElement(_components.a, {
    href: "https://google.com"
  }, "some link")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zsh"
  }, "printf \"%s\\n\" \"hello world from printf\"\nhello world from printf\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zsh"
  }, "$ printf \"%s\\n\" \"hello printf\" \"in\" \"bash script\"\nhello printf\nin\nbash script\n")), "\n", React.createElement(_components.h3, null, "Advanced Formatting"), "\n", React.createElement(_components.h4, null, "Table"), "\n", React.createElement(_components.p, null, "sdsd"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "#/bin/bash\ndivider=\"==============================\"\ndivider=$divider$divider\n\nheader=\"\\n %-10s %8s %10s %11s\\n\"\nformat=\" %-10s %08d %10s %11.2f\\n\"\n\nwidth=43\n\nprintf \"$header\" \"ITEM NAME\" \"ITEM ID\" \"COLOR\" \"PRICE\"\n\nprintf \"%$width.${width}s\\n\" \"$divider\"\n\nprintf \"$format\" \\\nTriangle 13  red 20 \\\nOval 204449 \"dark blue\" 65.656 \\\nSquare 3145 orange .7\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
